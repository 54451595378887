import api from '../../helpers/apirest/api';

export const state = {
    roles: null
}

export const mutations = {
    SET_ROLES(state, newValue) {
        state.roles = newValue;
    },
}

export const actions = {

    async loadList({ commit, state }) {

        if(!state?.roles){
            const response = await api.get({
                url: 'roles'
            });
    
            const data = response?.data?.data;         
    
            commit('SET_ROLES', data);
        }        
    }
}