import api from '@/helpers/apirest/api';

export const state = {
    menus: null
}

export const mutations = {
    SET_MENUS(state, newValue) {
        state.menus = newValue;
    },
}

export const getters = {
    
};

export const actions = {

    async loadList({ commit, state }) {

        if(!state?.menus){
            const response = await api.get({
                url: 'user/menus'
            });
    
            const data = response?.data?.data?.menus;         
            commit('SET_MENUS', data);
        }        
    }
}