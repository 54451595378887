import axios from 'axios';
import Vue from "vue";

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Accept': 'application/json',
  },
  withCredentials: true,
  withLoading: false,
//   xsrfCookieName: 'XSRF-TOKEN',
//   xsrfHeaderName: 'X-XSRF-TOKEN',
  withXSRFToken: true
});

//Interceptors
import axiosInterceptorsSetup from '../../interceptors/axios.interceptors';
axiosInterceptorsSetup(HTTP);

export default {
    get({url, params,  baseURL, config}) {
        return HTTP.get(url,{
            baseURL:baseURL,
            params:params,
            headers:{
                
            },
            ...config
        });
    },
    post({url, data,  baseURL, config}) {
        return HTTP.post(url, data, {
            baseURL:baseURL,            
            headers:{
                
            },
            ...config
        });
    },
    put({url, data,  baseURL, id, config}) {
        if(id){
            url += `/${id}`
        }
        return HTTP.put(url, data, {
            baseURL:baseURL,            
            headers:{
                
            },
            ...config
        });
    },
    patch({url, data,  baseURL, config}) {
        return HTTP.patch(url, data, {
            baseURL:baseURL,            
            headers:{
                
            },
            ...config
        });
    },
    delete({url, baseURL, id, successMsg, config, force = false}) {
        return HTTP.delete(`${url}/${id}${force ? '/force' : ''}`, {
            baseURL:baseURL,            
            headers:{
                
            },
            ...config
        }).then(() => {
            Vue.swal({
                position: "top-end",
                icon: "success",
                title: successMsg || "Acción ejecutada con exito",
                showConfirmButton: false,
                timer: 2000
            });
        });
    },
    async save({url, data,  baseURL, id, successMsg, config}){
        let response = null;
        if (id) {
            response = await this.put({ url, id, data, baseURL, config });
        } else {
            response = await this.post({ url, data, baseURL, config });
        }

        Vue.swal({
            position: "top-end",
            icon: "success",
            title: successMsg || "Acción ejecutada con exito",
            showConfirmButton: false,
            timer: 2000
        });

        return response;
    }
};