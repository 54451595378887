import api from '../../helpers/apirest/api';

export const state = {
    productCategories: []
}

export const mutations = {
    SET_PRODUCT_CATEGORIES(state, newValue) {
        state.productCategories = newValue;
    },
}

export const getters = {
    categoryTree: (state) => {
        function buildTree(categories) {
            return categories.map(category => ({
                id: category.id,
                name: category.name,
                children: category.product_category_recursive_children 
                          ? buildTree(category.product_category_recursive_children) // Llamada recursiva
                          : []
            }));
        }

        return state.productCategories ? buildTree(state.productCategories) : [];
    }
}

export const actions = {

    async loadList({ commit }, { params = {} } ) { 

        const response = await api.get({
            url: 'product-categories',
            params: params
        });

        const data = response?.data?.data;         

        commit('SET_PRODUCT_CATEGORIES', data);     
    }
}