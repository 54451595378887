import api from '../../helpers/apirest/api';

export const state = {
    identificationTypes: null
}

export const mutations = {
    SET_IDENTIFICATION_TYPES(state, newValue) {
        state.identificationTypes = newValue;
    },
}

export const actions = {

    async loadList({ commit, state }) {

        if(!state?.identificationTypes){
            const response = await api.get({
                url: 'identification-types'
            });
    
            const data = response?.data?.data;         
    
            commit('SET_IDENTIFICATION_TYPES', data);
        }        
    }
}