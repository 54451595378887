import api from '../../helpers/apirest/api';

export const state = {
    currentUser: sessionStorage.getItem('currentUser')
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('currentUser', newValue)
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    }
}

export const actions = {

    async login({ commit }, { email, password } = {}) {

        await api.get({
            baseURL: process.env.VUE_APP_WEB_URL,
            url: '/sanctum/csrf-cookie',
            config: {
                withLoading: true
            }
        });

        const response = await api.post({
            baseURL: process.env.VUE_APP_WEB_URL,
            url: '/login',
            data:{
                email:email,
                password:password
            },
            config: {
                withLoading: true
            }
        });

        const user = response?.data?.data;         

        commit('SET_CURRENT_USER', user);
    },

    async logout({ commit }) {

        await api.post({
            baseURL: process.env.VUE_APP_WEB_URL,
            url: '/logout',
            config: {
                withLoading: true
            }
        });

        commit('SET_CURRENT_USER', null);
    },

    async forgotPassword({ getters }, { email } = {}) {
        console.log( getters);

        await api.get({
            baseURL: process.env.VUE_APP_WEB_URL,
            url: '/sanctum/csrf-cookie',
            config: {
                withLoading: true
            }
        });

        await api.save({
            url: 'auth/forgot-password',
            data:{
                email:email
            },
            config: {
                withLoading: true
            }
        });
    },
    
    async validateResetToken({ getters }, { email, token } = {}) {
        console.log( getters);

        await api.get({
            baseURL: process.env.VUE_APP_WEB_URL,
            url: '/sanctum/csrf-cookie',
            config: {
                withLoading: true
            }
        });

        await api.post({
            url: 'auth/validate-reset-token',
            data:{
                token:token,
                email:email
            },
            config: {
                withLoading: true
            }
        });
    },
    
    async resetPassword({ getters }, { token, email, password, re_password } = {}) {
        console.log( getters);

        await api.get({
            baseURL: process.env.VUE_APP_WEB_URL,
            url: '/sanctum/csrf-cookie',
            config: {
                withLoading: true
            }
        });

        await api.post({
            url: 'auth/reset-password',
            data:{
                token:token,
                email:email,
                password:password,
                re_password:re_password,
            },
            config: {
                withLoading: true
            }
        });
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    if(state){
        sessionStorage.setItem(key, JSON.stringify(state))
    }else{
        sessionStorage.removeItem(key, JSON.stringify(state))
    }
}
