export const state = {
  layoutType: sessionStorage.getItem('layoutType') || 'vertical',
  layoutWidth: sessionStorage.getItem('layoutWidth') || 'fluid',
  leftSidebarType: sessionStorage.getItem('leftSidebarType') || 'dark',
  topbar: sessionStorage.getItem('topbar') || 'dark',
  loader: sessionStorage.getItem('loader') || false
}

export const getters = {}

export const mutations = {
  CHANGE_LAYOUT(state, layoutType) {
    sessionStorage.setItem('layoutType', layoutType);
    state.layoutType = layoutType;
  },
  CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
    sessionStorage.setItem('layoutWidth', layoutWidth);
    state.layoutWidth = layoutWidth;
  },
  CHANGE_LEFT_SIDEBAR_TYPE(state, leftSidebarType) {
    sessionStorage.setItem('leftSidebarType', leftSidebarType);
    state.leftSidebarType = leftSidebarType;
  },
  CHANGE_TOPBAR(state, topbar) {
    sessionStorage.setItem('topbar', topbar);
    state.topbar = topbar;
  },
  LOADER(state, loader) {
    sessionStorage.setItem('loader', loader);
    state.loader = loader
  }
}

export const actions = {
  changeLayoutType({ commit }, { layoutType }) {
    commit('CHANGE_LAYOUT', layoutType);
  },

  changeLayoutWidth({ commit }, { layoutWidth }) {
    commit('CHANGE_LAYOUT_WIDTH', layoutWidth)
  },

  changeLeftSidebarType({ commit }, { leftSidebarType }) {
    commit('CHANGE_LEFT_SIDEBAR_TYPE', leftSidebarType)
  },

  changeTopbar({ commit }, { topbar }) {
    commit('CHANGE_TOPBAR', topbar)
  },

  changeLoaderValue({ commit }, { loader }) {
    commit('LOADER', loader)
  }
}
