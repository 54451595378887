export const state = {
    loading: false
};

export const mutations = {
    CHANGE_LOADING(state, status) {
        state.loading = status;
    }
};

export const actions = {
    changeLoading({ commit },  status ) {
        commit('CHANGE_LOADING', status);
    },
}
