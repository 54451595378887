import store from '@/state/store';
import router from '../router'; 
//import Swal from "sweetalert2";
import Vue from "vue";

export default function setup(axios) {

    axios.interceptors.request.use(function (config) {
        if(config?.withLoading){
            store.dispatch('loading/changeLoading',true);            
        }
        return config;
    }, function (error) {
        store.dispatch('loading/changeLoading',false);
        return Promise.reject(error);
    });

    axios.interceptors.response.use(
        (response) => {
            store.dispatch('loading/changeLoading',false);
            return Promise.resolve(response);
        },
        (error) => {
            console.log(error);
            store.dispatch('loading/changeLoading',false);
            switch (error?.response?.status) {
                case 401:
                {
                    store.commit('auth/SET_CURRENT_USER', null);
                    router.push({ name: "login" });
                }
                break;
                case 422:
                {
                    const data = error?.response?.data;
                    const validationErrors = data?.extra[0]?.errors;

                    let errorList = '';
                    Object.entries(validationErrors).forEach((obj) => {
                        if(obj[1][0]){
                            errorList += `<li>${(obj[1][0])}</li>`;
                        }
                    });

                    Vue.swal({
                        position: "top-end",
                        icon: "warning",
                        title: data?.errors[0]?.title,
                        showConfirmButton: false,
                        html:  `<ul>${errorList}</ul>`,
                        timer: 20000
                    });
                }
                break;
                default:
                {
                    const data = error?.response?.data;

                    Vue.swal({
                        position: "top-end",
                        icon: "warning",
                        title: data?.errors[0]?.title || 'Ha ocurrido un error en el servidor',
                        showConfirmButton: false,
                        timer: 20000
                    });
                }
                break;
            }

            return Promise.reject(error);
        }
    );
}