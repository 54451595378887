import api from '../../helpers/apirest/api';

export const state = {
    customers: null
}

export const mutations = {
    SET_CUSTOMERS(state, newValue) {
        state.customers = newValue;
    },
}

export const actions = {

    async loadList({ commit, state }) {

        if(!state?.customers){
            const response = await api.get({
                url: 'customers'
            });
    
            const data = response?.data?.data;         
    
            commit('SET_CUSTOMERS', data);
        }        
    }
}