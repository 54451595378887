import api from '../../helpers/apirest/api';

export const state = {
    stores: null
}

export const mutations = {
    SET_STORES(state, newValue) {
        state.stores = newValue;
    },
}

export const actions = {

    async loadList({ commit }) {
        const response = await api.get({
            url: 'stores'
        });

        const data = response?.data?.data;         

        commit('SET_STORES', data);
    }
}