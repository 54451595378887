export default {
    inserted(el, binding, vnode) {
        const permission = binding.value.permission;
        const action = binding.value.action || 'remove';
        const hasPermission = vnode.context.$store.getters['permission/hasPermission'];
        if (!hasPermission(permission)) {
          if (action === 'disable') {
            el.disabled = true;
            el.classList.add('disabled');
            el.title = 'No tienes permisos para realizar esta acción!';
          } else {
            el.parentNode && el.parentNode.removeChild(el);
          }
        }
      }
};
